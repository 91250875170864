const data = JSON.stringify({
  total_pages: 6,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Динамика',
      description: '',
      questions: [
        {
          id: 999,
          nr: 999,
          show_id: false,
          question: "Укажите, отметили ли Вы какие-либо изменения в своем состоянии с тех пор, как Вы заполнили предыдущую часть данной анкеты?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Да, чувствую себя отлично (Все симптомы прошли окончательно)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, стало заметно лучше (Большинство симптомов исчезло)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, стало несколько лучше (Большинство симптомов всё еще присутствует)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Нет, изменений практически нет (Чувствую себя так же, как и в прошлый раз)",
              points: 3
            },
            {
              id: 5,
              desc: "",
              name: "Да, стало хуже (Мое состояние хуже, чем в прошлый раз)",
              points: 4
            }
          ]
        },
      ]
    },
    {
      id: 2,
      page_nr: 2,
      subtitle: 'Типичные',
      description: 'Пожалуйста, укажите, отмечали ли Вы следующие симптомы в течение последних 24 часов, и оцените степень их выраженности:',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "Учащенное мочеиспускание малыми объемами мочи (частое посещение туалета)",
          answers: [
            {
              id: 1,
              desc: "до 4 раз в день",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "5‐6 раз в день",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "7‐8 раз в день",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "9‐10 раз в день и чаще",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "Срочные (сильные и неудержимые) позывы к мочеиспусканию",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "Боль или жжение при мочеиспускании",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "Чувство неполного опорожнения мочевого пузыря",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "Боль или дискомфорт внизу живота (надлобковой области)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "Наличие крови в моче",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 3,
      page_nr: 3,
      subtitle: 'Дифференциальные',
      description: 'Пожалуйста, укажите, отмечали ли Вы следующие симптомы в течение последних 24 часов, и оцените степень их выраженности:',
      questions: [
        {
          id: 7,
          nr: 7,
          show_id: true,
          question: "Боль в поясничной области (может быть односторонней)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
        {
          id: 8,
          nr: 8,
          show_id: true,
          question: "Гнойные выделения из половых путей (особенно по утрам)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
        {
          id: 9,
          nr: 9,
          show_id: true,
          question: "Гнойные выделения из мочевыводящих путей (вне акта мочеиспускания)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
        {
          id: 10,
          nr: 10,
          show_id: true,
          question: "Озноб (познабливание) /чувство повышенной температуры тела. (Если измеряли, укажите значения)",
          answers: [
            {
              id: 1,
              desc: "≤37.5 C",
              name: "Нет",
              points: 0
            },
            {
              id: 2,
              desc: "37.6-37.9 C",
              name: "Да, слабо",
              points: 1
            },
            {
              id: 3,
              desc: "38.0-38.9 C",
              name: "Да, умеренно",
              points: 2
            },
            {
              id: 4,
              desc: "≥39.0 C",
              name: "Да, сильно",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 4,
      page_nr: 4,
      subtitle: 'Качество жизни',
      description: '',
      questions: [
        {
          id: 11,
          nr: 11,
          show_id: true,
          question: "Пожалуйста, укажите, насколько было выражено чувство дискомфорта, вызванное вышеуказанными симптомами, в течение последних 24 часов:",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Никакого дискомфорта (Нет никаких симптомов. Чувствую себя как обычно)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Чуть заметный дискомфорт (Чувствую себя чуть хуже обычного)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Выраженный дискомфорт (Чувствую себя заметно хуже обычного)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Очень сильный дискомфорт (Чувствую себя ужасно)",
              points: 3
            },
          ]
        },
        {
          id: 12,
          nr: 12,
          show_id: true,
          question: "Пожалуйста, укажите, насколько вышеуказанные симптомы мешали Вашей повседневной активности/работоспособности в течение последних 24 часов:",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нисколько не мешали (Работаю как в обычные дни, без затруднений)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Мешали незначительно (Из-за возникших симптомов, работаю чуть меньше)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Значительно мешали (Повседневная работа требует больших усилий)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ужасно мешали (Практически не могу работать)",
              points: 3
            },
          ]
        },
        {
          id: 13,
          nr: 13,
          show_id: true,
          question: "Пожалуйста, укажите, насколько вышеуказанные симптомы мешали Вашей общественной активности (поход в гости, встречи с друзьями и т.п.) в течение последних 24 часов:",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нисколько не мешали (Моя деятельность и активность никоим образом не изменились, живу как обычно)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Мешали незначительно (Незначительное снижение деятельности)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Значительно мешали (Значительное снижение. Больше сижу дома)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ужасно мешали (Ужасно. Практически не выходила из дому)",
              points: 3
            },
          ]
        },
      ]
    },
    {
      id: 5,
      page_nr: 5,
      subtitle: 'Дополнительные',
      description: 'Пожалуйста, ответьте, имеются ли у Вас на момент заполнения анкеты следующие:',
      questions: [
        {
          id: 14,
          nr: 14,
          show_id: true,
          question: "Менструальные выделения?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Да",
              points: "yes"
            }
          ]
        },
        {
          id: 15,
          nr: 15,
          show_id: false,
          question: "Так называемый «предменструальный синдром» (ПМС) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Да",
              points: "yes"
            }
          ]
        },
        {
          id: 16,
          nr: 16,
          show_id: false,
          question: "Признаки климактерического синдрома?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Да",
              points: "yes"
            }
          ]
        },
        {
          id: 17,
          nr: 17,
          show_id: false,
          question: "Беременность?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Да",
              points: "yes"
            }
          ]
        },
        {
          id: 18,
          nr: 18,
          show_id: false,
          question: "Сахарный диабет, выявленный ранее?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Нет",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Да",
              points: "yes"
            }
          ]
        },
      ]
    },
    {
      id: 6,
      page_nr: 6,
      subtitle: '',
      description: ''
    }
  ],
})

export default data
